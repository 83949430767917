<style lang="less" scoped>
.home {
  height: 100vh;
  overflow: auto;
  .banner {
    height: 526px;
    padding-top: 54px;
  }
  .block {
    position: relative;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .block::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
  }
  .block-gray {
    height: 100px;
    background: #ededed;
    padding: 16px 64px 0 134px;
    input {
      background: none;
      width: 100%;
      height: 100%;
    }
  }
  .gay-1 {
    background: #ededed url('../../assets/img/icon_1.png') no-repeat 64px center;
    background-size: 60px 60px;
  }
  .gay-2 {
    background: #ededed url('../../assets/img/icon_2.png') no-repeat 64px center;
    background-size: 60px 60px;
  }
  .gay-3 {
    background: #ededed url('../../assets/img/icon_3.png') no-repeat 64px center;
    background-size: 60px 60px;
  }
  .bottom {
    padding: 80px 10px 50px;
    button {
      height: 100px;
      width: 100%;
      background-color: #26a7f8;
      color: #fff;
      border: none;
      border-radius: 50px;
      font-size: 32px;
      font-weight: 500;
    }
  }
}
</style>
<template>
  <div class="home">
    <van-swipe class="banner" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <van-image
          width="100%"
          height="100%"
          fit="contain"
          :src="require('../../assets/img/banner_1.png')"
        />
      </van-swipe-item>
      <van-swipe-item>
        <van-image
          width="100%"
          height="100%"
          fit="contain"
          :src="require('../../assets/img/banner_2.png')"
        />
      </van-swipe-item>
      <van-swipe-item>
        <van-image
          width="100%"
          height="100%"
          fit="contain"
          :src="require('../../assets/img/banner_3.png')"
        />
      </van-swipe-item>
      <van-swipe-item>
        <van-image
          width="100%"
          height="100%"
          fit="contain"
          :src="require('../../assets/img/banner_4.png')"
        />
      </van-swipe-item>
      <van-swipe-item>
        <van-image
          width="100%"
          height="100%"
          fit="contain"
          :src="require('../../assets/img/banner_5.png')"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="block">
      <img src="../../assets/img/bg_1.jpg" alt="">
    </div>
    <div class="block-gray gay-1">
      <input
        v-model="name"
        placeholder="请输入您的姓名"
      />
    </div>
    <div class="block">
      <img src="../../assets/img/bg_2.jpg" alt="">
    </div>
    <div class="block-gray gay-2">
      <input
        v-model="idCard"
        placeholder="请输入18位第二代身份证号"
        maxlength="18"
      />
    </div>
    <div class="block">
      <img src="../../assets/img/bg_3.jpg" alt="">
    </div>
    <div class="block-gray gay-3">
      <input
        v-model="phone"
        placeholder="请输入本人实名的11位手机号"
        maxlength="11"
      />
    </div>
    <div class="bottom">
      <button @click="submit">立即提交</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import {} from '@/apis'
import { Dialog } from 'vant'

export default {
  name: 'home',
  components: {},
  data() {
    return {
      name: '',
      idCard: '',
      phone: ''
    }
  },
  created() {
    let form = sessionStorage.getItem('form')
    if (form) {
      form = JSON.parse(form)
      this.name = form.name
      this.idCard = form.idCard
      this.phone = form.phone
    }
  },
  methods: {
    submit() {
      if (!this.name) {
        Dialog.alert({
          title: '提示',
          message: '姓名不能为空',
        })
        return
      }
      if (!this.idCard) {
        Dialog.alert({
          title: '提示',
          message: '身份证号不能为空',
        })
        return
      }
      if (this.idCard.length !== 16 && this.idCard.length !== 18) {
        Dialog.alert({
          title: '提示',
          message: '身份证号格式不正确',
        })
        return
      }
      if (!this.phone) {
        Dialog.alert({
          title: '提示',
          message: '手机号不能为空',
        })
        return
      }
      if (this.phone.length !== 11) {
        Dialog.alert({
          title: '提示',
          message: '手机号格式不正确',
        })
        return
      }
      sessionStorage.setItem('form', JSON.stringify({
        name: this.name,
        idCard: this.idCard,
        phone: this.phone
      }))
      this.$jumpTo('/result')
    }
  }
}
</script>
